<template>

    <div id="app" class="dark-color" style="overflow: hidden;">
        <div id="home-page" class="desktop row">

            <a :href="$router.resolve({ name: 'HomeCare' }).href" tag="div" class="split-pane col-xs-12 col-sm-6 uiux-side">
                <div class="container">
                    <div class="inner">
                        <h1>Home Health Care</h1>
                        <div class="subcontainer">
                            <h2 style="font-size: 0.4em">We accept private pay</h2>
                            <!-- <h4>Successful Aging Care Net</h4> -->
                            <div class="lineContainer">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <!-- Education -->
            <a :href="$router.resolve({ name: 'NurseAideTrainingServices' }).href" tag="div" class="split-pane col-xs-12 col-sm-6 frontend-side">
                <div class="container">
                    <div class="inner">
                        <h3>Education: Nurse Aide Training</h3>
                        <div class="subcontainer">
                            <h4 style="font-size: 0.4em">Successful Aging Career Institute</h4>
                            <div class="lineContainer">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

            <!-- <router-link tag="div" class="split-pane col-xs-12 col-sm-6 uiux-side" :to="{ name: 'HomeCare' }">
                <div class="container">
                    <div class="inner">
                        <h3>Homecare</h3>
                        <div class="subcontainer">
                            <h4>Successful Aging Home Health</h4>
                            <div class="lineContainer">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link> -->

            <!-- <router-link tag="div" class='split-pane col-xs-12 col-sm-6 frontend-side' :to="{ name: 'Education' }">
                <div class="container">
                    <div class="inner">
                        <h3>Education</h3>
                        <div class="subcontainer">
                            <h4>Successful Aging Education Center</h4>
                            <div class="lineContainer">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link> -->

            <!-- <div id='split-pane-or' :class="mobileOS()" style="left: 0!important; right: 0!important; z-index: 9;">
                <span style="width: 105px!important; height: 105px!important; border-radius: 50%; display: inline-block; line-height: 100px;">
                    <img src='~@/assets/images/small_logo.png' alt="Successful Aging Care">
                </span>
            </div> -->

            <div class="custom-footer" id="footer">
                <div class="copy-right mt-2" style="color: #fff;">
                    Powered by <strong>
                        <a href="http://crmzz.com" target="_blank" class="website-main-color" style="color: #fff!important;">CRMzz</a>
                    </strong>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
export default {
    methods: {
        getMobileOperatingSystem() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return 'windows-phone';
            }

            if (/android/i.test(userAgent)) {
                return 'android';
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'ios';
            }

            return 'unknown';

        },
        mobileOS() {
            return this.getMobileOperatingSystem()
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css');
</style>

<style lang="scss">
// @import url('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,800');

@media (min-width: 500px) {
    .col-sm-6 {
        width: 50%;
    }
}
.frontend-side {
    background-image: url('./../assets/images/education.jpg');
}
.uiux-side {
    background-image: url('./../assets/images/home-health-care.jpg');
}
.split-pane:after {
    content: "";
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.split-pane {
    position: relative;
    cursor: pointer;
    // padding-top: 1em;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center center;
    height: 50%;
    min-height: 8em;
    font-size: 2em;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight:300;
}
@media(min-width: 500px) {
  .split-pane {
    padding-top: 2em;
    height: 100%;
  }
}
.split-pane > div {
  position: relative;
  top: 70%;
//   -webkit-transform: translateY(-50%);
//   -ms-transform: translateY(-50%);
//   transform: translateY(-50%);
  text-align: center;
}
.split-pane > div .text-content {
  line-height: 1.6em;
  margin-bottom: 1em;
}
.split-pane > div .text-content .big {
  font-size: 2em;
}
.split-pane > div img {
  height: 1.3em;
}
@media (max-width: 500px) {
  .split-pane > div img {
    display:none;
  }
}
.split-pane button, .split-pane a.button {
  font-family: 'Open Sans', sans-serif;
	font-weight:800;
  background: none;
  border: 1px solid white;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 15em;
  padding: 0.7em;
  font-size: 0.5em;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-decoration: none;
  color: white;
  display: inline-block;
	cursor: pointer;
}
.split-pane button:hover, .split-pane a.button:hover {
  text-decoration: none;
  background-color: white;
  border-color: white;
	cursor: pointer;
}
.uiux-side.split-pane button:hover, .split-pane a.button:hover {
  color: violet;
}
.frontend-side.split-pane button:hover, .split-pane a.button:hover {
  color: blue;
}

#split-pane-or {
    font-size: 2em;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0!important;
    right: 0!important;

    &.android,
    &.windows-phone,
    &.unknown {
        -webkit-transform: translateY(-50%)!important;
        -ms-transform: translateY(-50%)!important;
        transform: translateY(-50%)!important;
    }

    &.ios {
        -webkit-transform: translateY(0)!important;
        -ms-transform: translateY(0)!important;
        transform: translateY(0)!important;
    }
}
@media (max-width: 925px) {
    #split-pane-or {
        top: 15%;
        left: 0!important;
        right: 0!important;
    }
}
#split-pane-or > span img {
    // height: 2.5em;
    height: auto;
    max-width: 105px!important;
}
@media (min-width: 500px) {
    .mobile-footer {
        display: none!important;
    }
}
@media (max-width: 500px) {
    #home-page {
        .container {
            width: 90%!important;
            top: 50%!important;

            .inner {
                h3,
                h1 {
                    font-size: 1.5vh!important;
                }
                .subcontainer {
                    h4,
                    h2 {
                        font-size: 1.7vh!important;
                    }
                }
            }
        }

        .custom-footer {
            display: none!important;
        }

        .mobile-footer {
            top: 0!important;
            bottom: unset!important;
            display: block!important;

            li {
                a {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                }

                line-height: 50px;
            }
        }
    }
    #split-pane-or {
        position: absolute;
        top: 50%;
        left: 0!important;
        right: 0!important;
    }
    #split-pane-or > span img {
        // height: 2.5em;
        height: auto;
        max-width: 105px!important;
    }
}
@media (min-width: 500px) {
    #split-pane-or {
        font-size: 3em;
        left: 0!important;
        right: 0!important;
    }
}
.big {
    font-size: 2em;
}
#slogan {
  position: absolute;
  width: 100%;
  z-index: 100;
  text-align: center;
  vertical-align: baseline;
  top: 0.5em;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
}
@media(min-width: 500px) {
  #slogan {
    top: 5%;
    font-size: 1.8em;
  }
}
#slogan img {
  height: 0.7em;
}
.bold {
	text-transform:uppercase;
}
.big {
	font-weight:800;
}

#home-page {
    height: 100vh!important;

    .venue-logo-container {
        margin: 0px auto;
        text-align: center;
        position: absolute;
        z-index: 999999999;
        right: 0;
        left: 0;
        top: 10px;
    }

    .container {
        position: absolute;
        // bottom: 15%;
        color: #fff;
        text-align: left;
        width: 60%;
        transition: all .5s ease;
        z-index: 9;
        // -webkit-transform: translateY(0) skewX(-15deg);
        // -ms-transform: translateY(0) skewX(-15deg);
        // transform: translateY(0) skewX(-15deg);
        padding: 0 30px;
        overflow: hidden;

        .inner {
            // -webkit-transform: skewX(15deg);
            // -ms-transform: skewX(15deg);
            // transform: skewX(15deg);

            h3,
            h1 {
                font-family: 'AgencyFB-Light', sans-serif;
                text-transform: uppercase;
                font-size: 1.5vw;
                line-height: 1em;
                margin:0;
                color: #fff;
                // text-shadow: 1px 2px 0px #eee, 1px 1px 2px #eee;
                // text-shadow: 2px 2px 2px #19c2b7;
            }

            .subcontainer {
                position: relative;
                overflow: hidden;
                margin: 0;

                h4,
                h2 {
                    text-transform: uppercase;
                    letter-spacing: 4px;
                    float: left;
                    margin: 0 8px 0 2px;
                    font-size: .8vw;
                    color: #fff;
                    // text-shadow: 1px 2px 0px #eee, 1px 1px 2px #eee;
                    // text-shadow: 2px 2px 2px #19c2b7;
                }

                .lineContainer {
                    position: relative;
                    height: 2px;
                    overflow: hidden;
                    margin: .30em 0 0 0;

                    .line {
                        position: absolute;
                        border-top: 1px solid #fff;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transition: -webkit-transform .5s ease;
                        transition: transform .5s ease;
                        transition-delay: 1.75s;
                    }
                }
            }
        }
    }

    .custom-footer,
    .mobile-footer {
        padding: 0!important;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9;
        background: transparent!important;
        text-align: center!important;

        .row {
            margin: 0!important;
        }

        .copy-right {
            padding: 0.5rem!important;
            // margin-bottom: 0.5rem!important;
            border-top: 1px solid rgba(255, 255, 255, 0.12);
        }
    }
}
</style>
